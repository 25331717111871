$(document).ready(function() {
	$("form").addClass("infield-active");
	$(".infield").inFieldLabels();
	ratesTabs();
	$("nav").click(function() {
		$(this).toggleClass("active");
	});
});
export function ratesTabs(){
	//$(".rates article").hide();
	$(".rates-nav a").click(function(e) {
		e.preventDefault();
		$(this).parent().addClass("active");
		$(this).parent().siblings().removeClass("active");
		var href = $(this).attr("href");
		$(href).show();
		$(href).siblings().hide();
	});
	var activeLink = $(".rates-nav a:first");
	if (document.location.hash) {
		var potentialActiveLink = $(".rates-nav a[href='"+document.location.hash+"']");
		if (potentialActiveLink.length >0) activeLink = potentialActiveLink;
	}
	activeLink.trigger("click");
}
export function facilitiesSliderTouch() {
	document.removeEventListener("touchstart", facilitiesSliderTouch);
	$(".facilities .scroller").css({overflow:"hidden",marginLeft:"0px"});
	ScrollFeatures();
	$(".facilities .scroller").hover(
		function(){$(".facilities .scroller").stop(); },
		function(){ ScrollFeatures();}
	);
}
document.addEventListener("touchstart", facilitiesSliderTouch);
var msPerPixel = 10;
export function ScrollFeatures() {
	var offset = $(".facilities .scroller .scroll:first")[0].offsetWidth;
	$(".facilities .scroller").animate({"margin-left":"-"+offset+"px"},(offset +  parseInt($(".facilities .scroller").css("margin-left")))*msPerPixel,"linear",function() {
		$(this).css({"margin-left":"0"}).append($(".facilities .scroller .scroll:first"));
		ScrollFeatures();
	});
}
